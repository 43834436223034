.section.biography {
  position: relative;
  overflow: hidden;
  min-height: 710px;
}

.biography--subtitle {
  font-size: 1.1rem;
  margin-top: 2.5rem;
  font-weight: 500;
  padding-left: 14vw;
}

.biography--text {
  width: 65vw;
  text-align: left;
  margin-block: 10px;
  margin-inline: 15vw;
}


.biography--image {
  position: absolute;
  width: 450px;
  top: 80px;
  right: -100px;
}

/* Media queries */
@media (max-width: 900px) {
  .biography--image {
    width: 400px;
    opacity: 0.1;
  }

}

@media (min-width: 900px) {

  .biography--text {
    width: 45vw;
  }

  .biography--image {
    width: 410px;
  }
}

@media (min-width: 1200px) {

  .biography--image {
    top: 60px;
    right: -80px;
  }
}
