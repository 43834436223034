.footer {
  width: 100%;
  background-color: black;
  padding-block: 10px;
}

.footer--text {
  color: white;
  font-size: 0.8rem;
}
