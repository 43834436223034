.gallery {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 40px;
}

.gallery--image {
  max-width: 450px;
  max-height: 450px;
  object-fit: cover;
  border-radius: 15px;
  /* animation: fadeIn 2s linear;
  -webkit-animation: fadeIn 2s linear forwards; */
}

.gallery--arrow {
  font-size: 3rem;
  color: #9ab4d3;
  cursor: pointer;
}

/* @keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
} */

@media (max-width: 576px) {
  .gallery--image {
    max-width: 250px;
  }

  .gallery--arrow {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .gallery--image {
    width: 350px;
  }
}
