.landing {
  position: relative;
  height: 58vh;
}

.landing--text {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  max-width: 280px;
  margin: 160px auto 10px;
  padding-top: 100px;
}

.landing--image {
  width: 489px;
  position: absolute;
  top: -90px;
  left: -223px;
}


.landing--menu {
  max-width: 280px;
  margin: 45px auto 10px;
  text-decoration: none;
}

.landing--menu-link {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.landing--menu-link:hover {
  color: black;
}



@media (min-width: 992px) {
  .landing--image {
    width: 590px;
  }
}

@media (max-width: 768px) {
  .landing--image {
    z-index: -1;
    opacity: 0.1;
  }
}

@media (min-width: 1440px) {

  .landing {
    height: 70vh;
  }
}
