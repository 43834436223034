.purple {
  background-color: #B9ACEB;
}

.blue {
  background-color: #ACC9EB;
}

.pink {
  background-color: #EBB7AC;
}


.headings--circle {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.125rem;
  margin-right: 1em;
  border: none;
  box-shadow: 4px 4px #000;
}


.headings--title {
  font-size: 1.2rem;
  margin-bottom: 0;
  font-weight: 600;
}

.headings--div {
  display: flex;
  align-items: center;
  padding-left: 8rem;
}

.section {
  text-align: left;
  margin-top: 5rem;
  /* margin-left: 2rem; */
  padding-top: 10px;
}


@media (max-width: 576px) {
  .headings--div {
    flex-direction: column;
  }

  .headings--circle {
    margin-bottom: 1em;
  }

  .headings--title {
    text-align: center;
    width: 350px;
  }
}

@media (max-width: 768px) {
  .headings--circle {
    width: 5.3vh;
    height: 5.3vh;
  }

  .headings--div {
    justify-content: center;
    padding-left: 0;
  }

  .headings--title {
    max-width: 490px;
    font-size: 1.1rem;
  }

}

@media (max-width: 375px) {
  .headings--title {
    max-width: 230px;
  }
}
