.writings--subtitle-box {
  margin-top: 2.5rem;
  /* width: 143px; */
  /* margin: 0 auto; */
  /* background-color: #ACC9EB; */
  padding: 7px 10px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* border-radius: 4px;
  border: 3px solid rgb(128, 153, 182);
  box-shadow: 5px 5px rgb(128, 153, 182); */
}

.writings--prose {
  .book--container-first {
    padding-left: 10.91vw;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    .book--cover {
      width: 250px;
      border-radius: 4px;
    }

    .writings--piece-title {
      font-size: 1.1rem;
      font-weight: 600;
      font-style: italic;
      margin: 0;
      padding-left: 0;
    }

    .book--text {
      flex: 1;
      padding-right: 3rem;

      .book--text-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;

        .writings--piece-title {
          font-size: 1.1rem;
          font-weight: 600;
          font-style: italic;
          margin: 0;
          padding-left: 0;
        }
      }
    }
  }

  .book--container-second {
    padding-left: 10.91vw;
    margin-top: 2rem;

    .book--praise-title {
      font-size: 0.9rem;
      font-weight: 600;
      font-style: italic;
      margin: 0;
      padding-left: 0;
      margin-bottom: 0.3rem;
    }

    .book--praise-text {
      font-size: 0.9rem;
    }
  }
}

.smaller-print {
  font-style: normal;
  font-weight: 500;
  font-size: 0.87rem;
}

.writings--poetry {
  margin: 70px 0px;
}


.writings--subtitle {
  font-size: 1.2rem;
  margin-top: 40px;
  margin-bottom: 30px;
  padding-left: 10.91vw;
  font-weight: 600;
  color: #8099B6;
}

.writings--piece-title {
  font-size: 1.1rem;
  padding-left: 14vw;
  margin-top: 15px;
  font-weight: 600;
  font-style: italic;
}

.writings--piece-text {
  padding-inline: 15vw;
  margin-bottom: 45px;
  min-width: 45vw;
  text-align: left;
}

.writings--gallery_info {
  text-align: center;
  margin-top: 1em;
}

.writings--link {
  text-decoration: none;
  color: black;
}

.writings--link.bold {
  font-weight: 600;
}

.writings--link:hover {
  color: #8099B6;
}

@media (min-width: 992px) {
  .section.writings {
    margin-top: 3rem;
  }
}
