.extra {
  min-height: 600px;
  position:relative;
  overflow: hidden;
}

.extra--text {
  text-align: center;
  margin: 0;
}

.extra--text_box {
  margin-top: 95px;
  max-width: 45vw;
  margin-inline: auto;
}

.extra--image {
  width: 400px;
  position: absolute;
  top: 300px;
}


@media (max-width: 768px) {
  .extra--text_box {
    margin-top: 6.6vw;
    max-width: 80vw;
  }

  /* .extra--image {
    opacity: 0.3;
  } */
}
