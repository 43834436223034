nav {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.7fr;
  grid-gap: 16px;
  background-color: white;
  border: 3px solid black;
  padding: 5px 20px;
  color: black;
}


.nav--home {
  text-decoration: none;
  font-weight: 700;
  color: black;
  font-size: 0.9rem;
}

.nav--home:hover {
  color: #ACC9EB;
}

.nav--name-title {
  transform: translateY(70%);
  border: 3px solid black;
  background-color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 700;
  max-width: 20rem;
  text-align: center;
  margin: auto;
  box-shadow: 7px 7px #000;
}

.nav--name-sub {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  max-width: 260px;
  margin: auto;
  transform: translateY(90%);
}

.nav--socials {
  list-style: none;
  margin-bottom: 0;
}

.nav--socials > li {
  display: inline-block;
  padding: 0 0.6rem;
}

.nav--socials-link {
  font-size: 0.9rem;
  font-weight: 600;
  color: black;
  text-decoration: none;
}

.nav--socials-link:hover {
  color: #ACC9EB;
}

@media (min-width: 100px) {
  nav {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .nav--socials-block {
    order: 1;
  }

  .nav--name {
    order: 2;
    height: 30px;
  }

  .nav--name-title {
    transform: translateY(35%);
  }

  .nav--socials {
    text-align: center;
    padding: 0;
  }
}

@media (min-width: 992px) {
  nav {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .nav--socials-block {
    order: 2;
  }

  .nav--name {
    order: 1;
  }

  .nav--socials {
    text-align: right;
  }
}
